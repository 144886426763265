import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import common_nl from './locales/nl/nl.json';




import Backend from 'i18next-http-backend';

// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n
   // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
   // learn more: https://github.com/i18next/i18next-http-backend
   .use(Backend)
   .use(initReactI18next)
   // init i18next
   // for all options read: https://www.i18next.com/overview/configuration-options
   .init({
      lng: 'nl',
      fallbackLng: 'nl',
      debug: false,
      interpolation: {
         escapeValue: false, // not needed for react as it escapes by default
      },
      resources: {
         nl:{
            common: common_nl,
         }
      },
   });

export default i18n;
